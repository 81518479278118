<script>
export default {
  metaInfo: {
    title: "LDAP Content",
  }
};
</script>

<script setup>
import PhpServiceTableView from '@/components/basic/php-wrappers/PhpServiceTableView.vue';
</script>

<template>
  <PhpServiceTableView url="?f=configuration&f2=ldapContent"/>
</template>

<style scoped>

</style>